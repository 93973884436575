.assets-outer-container {
  width: 100%;
}
.assets-inner-container {
  width: 80%;
  margin: auto;
}
.assets-card-contianer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0;
}
.assets-nav-card {
  margin: 10px;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  cursor: pointer;
  /* background-image: linear-gradient(to bottom, #ffc2d5, #ffb7c6, #feadb5, #fba4a2, #f69c8f); */
}
.assets-nav-card img {
  width: 40px;
}

.video-container {
  display: grid;
  gap: 1rem;
}
.assets-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.assets-modified-date {
  color: #848484;
  font-size: 0.8rem;
  padding: 0 !important;
}
.assets-name {
  color: #000000;
  padding: 0 !important;
}
.assets-video-wrapper {
  width: 100%;
}
.assets-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.assets-details a {
  display: flex;
  gap: 3px;
}

.leaflets-container {
  width: 100%;
  display: grid;
}

.assets-card {
  display: flex;
  gap: 30px;
  align-items: center;
  width: 100%;
}
.assets-card img {
  width: 200px;
}

.assets-loader-container {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assets-loader {
  height: 4px;
  width: 130px;
  background: linear-gradient(#f05742 0 0), linear-gradient(#f05742 0 0),
    #e6e6e6;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 1.5s infinite;
}

@keyframes p6 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}
