.partners-demo-footer-container {
  width: 100%;
  margin: auto;
  background-color: #f05742;
  border-radius: 15px 15px 0 0;
}
.partners-demo-footer-inner-container {
  width: 80%;
  margin: auto;
  padding: 1rem 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.partners-demo-footer-right ul {
  display: flex;
  align-items: center;
  gap: 20px;
}
.partners-demo-footer-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
}
.partners-demo-footer-right ul {
  cursor: pointer;
  list-style: none;
}
.partners-demo-footer-right ul li a i {
  color: #fff;
}
