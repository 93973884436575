.cycle-mart-container {
  width: 100%;
  height: 100%;
  font-family: "Inter", sans-serif;
}

.cycle-mart-main-header {
  width: 100%;
  height: 10vh;
}
.cycle-mart-main-header-nav-buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding: 20px;
}

.cycle-mart-header {
  width: 80%;
  left: 20%;
  position: fixed;
  right: 0;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  background-color: #dae9ff;
  z-index: 99;
}

.cycle-mart-header-logo {
  position: relative;
  right: 45px;
}

.cycle-mart-header-nav-links ul li {
  list-style-type: none;
  display: inline;
  position: relative;
  padding: 20px;
  margin-right: 20px;
  font-weight: bold;
  color: #050356;
}

.cycle-mart-header-logo img {
  width: 130px;
}

.cycle-mart-body-content {
  display: flex;
  height: 90vh;
  background: url(/public/webchat/inextlabs-faq-demo/images/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.cycle-mart-offers-section {
  width: 50%;
  text-align: center;
}

.cycle-mart-offer {
  margin-top: 170px;
  line-height: 60px;
}

.cycle-mart-title {
  font-weight: bold;
  font-size: 3rem;
  position: relative;
  color: #050356;
}

.cycle-mart-banner-section {
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cycle-mart-model {
  color: #52525b;
  font-size: 2rem;
}

.cycle-mart-offers-discount {
  font-size: 20px;
}

.cycle-mart-offers-discount span {
  color: #fd544d;
}

.cycle-mart-explore-button {
  border: hsl(218, 57%, 59%) 3px solid;
  font-size: 15px;
  position: fixed;
  bottom: 0;
  font-size: larger;
  position: relative;
  padding: 10px 20px;
}

.cycle-mart-footer p {
  background-color: #dae9ff;
  width: 100%;
  height: 5vh;
  padding-right: 20px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #52525b;
}

@media only screen and (min-width: 769px) and (max-width: 1124px) {
  .cycle-mart-banner-section {
    width: 500px;
  }

  .cycle-mart-model {
    font-size: 15px;
  }

  .cycle-mart-offers-discount {
    font-size: 18px;
  }

  .cycle-mart-footer {
    margin-top: 130px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 768px) {
  .cycle-mart-body-content {
    align-items: center;
    flex-direction: column;
    height: 80%;
  }

  .cycle-mart-model {
    font-size: 15px;
  }

  .cycle-mart-banner-section {
    width: 500px;
  }

  .cycle-mart-header-nav-links ul li {
    font-size: 13px;
    padding: 0px;
    margin-right: 14px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .cycle-mart-body-content {
    align-items: center;
    flex-direction: column;
    height: 80%;
  }

  .cycle-mart-model {
    font-size: 13px;
  }

  .cycle-mart-offers-discount {
    font-size: 13px;
  }

  .cycle-mart-banner-section {
    width: 400px;
  }

  .cycle-mart-header-nav-links ul li {
    font-size: 12px;
    padding: 0px;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .cycle-mart-body-content {
    align-items: center;
    flex-direction: column;
  }

  .cycle-mart-header {
    height: 50%;
    align-items: center;
  }

  .cycle-mart-header-logo {
    right: 45px;
  }

  .cycle-mart-header-nav-links ul {
    margin-right: 7px;
    position: relative;
  }

  .cycle-mart-header-nav-links ul li {
    font-size: 8px;
    padding: 0px;
    margin-right: 0px;
  }

  .cycle-mart-offer {
    margin-top: 80px;
    line-height: 40px;
  }

  .cycle-mart-banner-section {
    width: 300px;
  }

  .cycle-mart-title {
    font-size: 30px;
  }

  .cycle-mart-model {
    font-size: 11px;
  }

  .cycle-mart-offers-discount {
    font-size: 10px;
  }

  .cycle-mart-explore-button {
    font-size: 12px;
  }
}
