.partners-demo-script-page-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* padding: 2rem; */
  /* background-color: #f5f5f5; */
}
.partners-demo-script-page-left {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  padding: 4rem;
  /* margin: 4rem; */
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: bold;
  color: #000;
}
.partners-demo-script-page-left-inner {
  overflow: scroll;
}
.partners-demo-script-page-left-inner h1,
h2,
thead {
  font-size: 1.3rem;
  color: #3c3c3c;
  padding: 1rem 0;
}
.partners-demo-script-page-left-inner p,
li,
tbody {
  font-size: 15px;
  line-height: 1.3rem;
  font-weight: 400;
  color: #4e4e4e;
}
.partners-demo-script-page-right {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header-nav-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.header-nav-btn:hover {
  background-color: transparent !important;
}
.partners-demo-script-page-right h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}
.partners-demo-script-page-right p {
  width: 60%;
  color: #ffffff;
}
.partners-demo-script-page-right img {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
}
.partners-demo-script-page-right-inner-button-link {
  background: rgb(240, 87, 66);
  backdrop-filter: blur(1px);
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.35s ease;
  text-decoration: none;
}

@media (max-width: 768px) {
  .partners-demo-script-page-inner-container {
    flex-direction: column;
    height: auto;
  }
  .partners-demo-script-page-left {
    padding: 2rem;
  }
  .partners-demo-script-page-right {
    padding: 2rem;
  }

  .home-redirect-button svg {
    color: #f05742;
  }
  .partners-demo-script-page-right img {
    width: 50%;
  }
  .partners-demo-script-page-right-inner-button-link {
    padding: 10px 20px;
  }
}
