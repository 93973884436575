.container {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
  }
  
  .main-header {
    width: 100%;
    height: 10vh;
  }
  
  .header {
    width: 100%;
    position: fixed;
    right: 0;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    align-items: center;
    background-color: #ffffff;
    z-index: 99;
  }
  
  #header-navbar {
    display: flex;
  }
  
  .header-nav-links ul li {
    list-style-type: none;
    display: inline;
    position: relative;
    padding: 1rem;
    margin-left: 1.5rem;
    top: 10px;
    font-weight: bold;
    color: #050356;
  }
  
  .header-logo img {
    width: 10rem;
  }
  
  .appointment-button-section {
    display: flex;
    position: relative;
  }
  
  .appoinment-button {
    position: relative;
    background-color: #fe546e;
    color: #fff;
    font-weight: Bold;
    padding: 10px;
    border-radius: 2px;
    margin-left: 19px;
  }
  .header-nav-buttons {
    display: flex;
    gap: 20px;
  }
  .fa-angle-right {
    position: relative;
    right: 1rem;
    top: 0.5rem;
    font-size: 2rem;
    color: #fe546e;
  }
  
  .body-content {
    display: flex;
    width: 100%;
    height: 90vh;
    justify-content: space-around;
    flex-direction: column-reverse;
    background-image: url(../../public/webchat/pm-healthcare/images/backround.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .hospital-welcome {
    width: 100%;
    text-align: center;
    padding: 2rem;
  }
  
  .welcome-content {
    position: relative;
    top: 30%;
    line-height: 1.5;
  }
  
  .welcome-message-service {
    font-size: 1rem;
    font-weight: bold;
    color: #606060;
  }
  
  .welcome-message {
    font-weight: bold;
    font-size: 4rem;
    position: relative;
    color: #016187;
    line-height: 4rem;
  }
  
  .welcome-message span {
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #016187;
  }
  
  .banner-section {
    width: 100%;
    text-align: center;
  }
  
  .banner-section img {
    width: 600px;
    max-width: 50rem;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
    .header {
      align-items: center;
    }
  
    .header-nav-links {
      margin: 0;
      position: relative;
      top: 50px;
    }
  
    .header-nav-links ul li {
      display: block;
      margin: 10px 0;
      padding: 10px;
      font-size: 15px;
      text-align: center;
    }
  
    .appoinment-button-section {
      position: relative;
      top: 30px;
    }
  
    .appoinment-button {
      margin: 20px 0px;
      font-size: 15px;
      width: 100%;
      text-align: center;
    }
  
    #header-navbar {
      display: none;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      padding: 20px;
    }
  
    .body-content {
      flex-direction: column-reverse;
    }
  
    .hospital-welcome {
      width: 100%;
      text-align: left;
    }
  
    .banner-section img {
      width: 100%;
      max-width: 500px;
      margin-top: 50px;
    }
  
    .welcome-message-service {
      font-size: 10px;
      text-align: center;
      line-height: 40px;
    }
  
    .welcome-message {
      font-size: 45px;
      text-align: center;
      line-height: 50px;
    }
  }
  
  @media (max-width: 1120px) {
    .header-nav-links ul li {
      margin: 15px 0;
      padding: 10px;
      font-size: 15px;
      text-align: center;
    }
  }
  