.partner-demo-solution-outer-container {
  width: 100%;
}
.partner-demo-solution-inner-container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 2rem;
  border-bottom: 1px solid #e0e0e0;
  padding: 2rem 0;
}
.partner-demo-solution-heading {
  display: flex;
  align-items: flex-start;
  padding: 1rem 0;
}
.partner-demo-solution-heading h4 {
  /* color: #3c3c3c; */
  font-weight: bold;
}
.partner-demo-solution-heading p {
  color: #848484;
}
.partner-demo-solution-left-container {
  width: 40%;
  height: fit-content;
}
.partner-demo-solution-left-container img {
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 20px;
}
.partner-demo-solution-right-container {
  width: 100%;
}
.partner-demo-solution-left-asset {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #e0e0e063;
}
.partner-demo-solution-left-asset button {
  width: fit-content;
  background: #ffffff;
  padding: 10px;
  height: fit-content;
  color: #3c3c3c;
  font-size: 1.125rem;
  line-height: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  padding: 0.9rem;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
.coming-soon {
  position: absolute;
  top: 0;
  right: 5px;
  color: red;
  font-size: 8px;
  font-weight: 500;
  border-radius: 3px;
  z-index: 2;
}
/* .partner-demo-solution-left-asset button svg {
  color: #f05742;
} */
.partner-demo-solution-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem 0;
  gap: 1rem;

  padding-bottom: 2rem;
}
.partner-demo-solution-content-card {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  padding: 0.2rem 1rem;
  border-radius: 10px;
}
.partner-demo-solution-content-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-demo-solution-content-card-status {
  border-radius: 15px;
  color: red;
  font-size: 9px;
  position: absolute;
  top: 3px;
  right: 3px;
}

.partner-demo-solution-content-card h3 {
  color: #3c3c3c;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.partner-demo-solution-content-card p {
  color: #848484;
  line-height: 1.3rem;
}
.partner-demo-solution-content-card-link {
  text-decoration: none;
}
.partner-demo-solution-content-card-button {
  width: 100%;
  height: 100%;
  padding: 15px 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.282);
  color: white;
  transform: translateY(100%);
  transition: all 0.35s ease;
  position: absolute;
  top: 60%;
  left: 0%;
  /* opacity: 0; */
}

.partner-demo-solution-content-card:hover
  .partner-demo-solution-content-card-button {
  opacity: 1;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.partner-demo-solution-content-card-button a {
  /* background: ; */
  background: rgb(240, 87, 66);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.35s ease;
}

.solution-partner-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.solution-partner-modal {
  background-color: #ffffff;
  padding: 0 1rem 1rem 1rem;
  border-radius: 25px;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 1000px;
  max-width: 80%;
  max-height: 100%;
}

.solution-partner-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.solution-partner-modal-img {
  width: 90%;
  height: auto;
  margin: auto;
  border-radius: 20px;
}

.solution-partner-modal-video {
  width: 100%;
  height: auto;
  margin: auto;
  border-radius: 20px;
}

.solution-partner-modal-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}
/* //// responsiveness //// */

/* //////////////////////////////////////// tablet //////////////////////////////////////// */

@media (max-width: 1024px) {
  .solution-partner-modal-title {
    font-size: 1rem;
  }
  .partner-demo-solution-content {
    grid-template-columns: 1fr 1fr;
  }
  .partner-demo-solution-inner-container {
    width: 90%;
  }
  .partner-demo-solution-content-card {
    padding: 0.5rem;
  }
  .partner-demo-solution-content-card h3 {
    font-size: 1rem;
  }
  .partner-demo-solution-content-card p {
    font-size: 0.9rem;
  }
  .partner-demo-solution-content-card-button a {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .solution-partner-modal-title {
    font-size: 12px;
  }
  .partner-demo-solution-inner-container {
    flex-direction: column;
    gap: 2rem;
  }
  .partner-demo-solution-left-container {
    width: 100%;
  }
  .partner-demo-solution-left-container img {
    height: 300px;
  }
  .partner-demo-solution-content {
    grid-template-columns: 1fr;
  }
}
