
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-1nmdiq5-menu {
  max-height: 150px;
  overflow: hidden;
}
.tox-tinymce-aux {
  display: none !important;
}
#reportContainer {
  width: 80%;
  margin: auto;
}
.shape-loader {
  width: 50px;
  height: 28px;
  background: radial-gradient(farthest-side, #f05742 90%, #0000) 50% 0,
    radial-gradient(farthest-side, #f05742 90%, #0000) 100% 0;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  position: relative;
  animation: d3-0 1.5s linear infinite;
}
.shape-loader:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f05742;
  left: 0;
  top: 0;
  animation: d3-1 1.5s linear infinite,
    d3-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes d3-0 {
  0%,
  31% {
    background-position: 50% 0, 100% 0;
  }
  33% {
    background-position: 50% 100%, 100% 0;
  }
  43%,
  64% {
    background-position: 50% 0, 100% 0;
  }
  66% {
    background-position: 50% 0, 100% 100%;
  }
  79% {
    background-position: 50% 0, 100% 0;
  }
  100% {
    transform: translateX(calc(-100% / 3));
  }
}

@keyframes d3-1 {
  100% {
    left: calc(100% + 7px);
  }
}

@keyframes d3-2 {
  100% {
    top: -0.1px;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin:0;
  }
  img {
    max-width: none;
  }
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: inline;
    vertical-align: middle;
  }
}
ol,
ul,
menu {
  list-style: unset;
}
