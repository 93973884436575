* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body{
  font-size: 15px;
} */

.inextlabs-demo-web-container {
  width: 100%;
  /* height: 100vh;
  overflow: hidden; */
}

.inextlabs-demo-web-body {
  position: relative;
  top: -8rem;
}

.inextlabs-demo-web-body-images {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .inextlabs-demo-web-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 8rem;
  }

  #inextlabs-web-header-img {
    width: 100px;
    left: 0.6rem;
  }

  .inextlabs-web-welcome-title-message {
    top: 7rem !important;
    left: 6rem;
  }

  .inextlabs-web-welcome-title {
    font-size: 1.2rem;
  }

  #inextlabs-web-header-corner-image {
    display: none;
  }
}

/* ///villa genie */

.villa-genie-container {
  background: url(/public/images/villa-bg.svg) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.customer-bot-container {
  background: url(/public/images/csbot-bg.svg) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
