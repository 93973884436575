.inner-container {
  background-color: #08151f;
  width: 100%;
  height: auto;
}
.main-header {
  height: 150px;
}
.first-header {
  height: 50%;
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #132838;
}
.first-header-info-section {
  display: flex;
  gap: 30px;
  color: #fff;
}

.navitem {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

#header-search-field {
  padding: 7px 25px;
  border-radius: 5px;
}
.first-header-search-form {
  display: flex;
  gap: 10px;
}
.first-header-search-button {
  background: none;
  color: #fff;
  border: none;
}
.first-header-login-signup-buttons {
  display: flex;
  gap: 20px;
  font-weight: 700;
  color: #fff;
}
.signup-button {
  border: 2px solid #4c96b6;
  padding: 5px 15px;
  border-radius: 5px;
}

.login-button {
  padding: 5px 15px;
}
.second-header {
  height: 50%;
  padding: 0px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.second-header-logo img {
  max-width: 250px;
}
.second-header-navigations {
  display: flex;
  gap: 50px;
  padding: 0px 50px;
}
.second-header-nav-buttons {
  display: flex;
  gap: 15px;
}

.ant-btn {
  background-color: #4c96b6;
  display: flex;
}
.home-redirect-button {
  font-weight: 700;
}
.second-header-navigations a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}
.dropdown-button {
  visibility: hidden;
}
.banner-containter {
  /* background: url(./images/banner-bg.png); */
  height: 500px;
  background: url(../../public/webchat/skyline-bank/images/banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.banner-title-description {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 75%;
  position: relative;
  top: 15%;
  left: 10%;
  gap: 20px;
}

.banner-title {
  font-size: 50px;
  color: #b6ffff;
  font-weight: bold;
  line-height: 3.5rem;
}

.banner-description {
  font-size: 20px;
  color: #fff;
}

.banner-read-more-button {
  padding: 8px 25px;
  border: none;
  font-weight: bold;
  color: #fff;
  background-color: #4c96b6;
  border-radius: 5px;
  position: relative;
  left: 10%;
}
.offers-contacts-container {
  width: 100%;
  display: flex;
}
.offers-outer-container {
  width: 50%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
}
.offers-inner-container {
  width: 80%;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.offers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 15px;
}
.offer {
  display: grid;
}

.offers-header-title {
  font-weight: bold;
  color: #ef0404;
  font-size: 20px;
}

.offer-title {
  font-weight: bold;
  color: #00425f;
  font-size: 20px;
}
.offer-details-button {
  background: transparent;
  color: #35aee1;
  border-radius: 5px;

  text-decoration: none;
}

.contacts-details {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contacts-details-description {
  font-weight: bold;
  color: #fff;
}
.contacts-details-number {
  color: #5db1ff;
}
.contacts-details-button {
  padding: 8px 25px;
  border: none;
  font-weight: bold;
  color: #fff;
  background-color: #4c96b6;
  border-radius: 5px;
  width: fit-content;
}

@media (max-width: 768px) {
  .first-header {
    justify-content: right;
  }
  /* .second-header
   {
      flex-direction: column;
    } */
  .banner-title {
    font-size: 20px;
    color: #b6ffff;
    font-weight: bold;
    line-height: 1.5rem;
  }
  .dropdown-button {
    visibility: visible;
    color: #fff;
    font-size: 25px;
    position: relative;
    right: 15px;
  }
  .banner-title-description {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 75%;
    position: relative;
    top: 5%;
    left: 10%;
    gap: 20px;
  }

  .offers-contacts-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .contacts-details {
    width: 100%;
  }
  .offers-outer-container {
    width: 100%;
  }
  .offers {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 15px;
  }

  .first-header-info-section {
    display: none;
  }

  .second-header-navigations {
    display: none;
  }
  #header-search-field {
    display: none;
  }
  .signup-button {
    white-space: nowrap;
  }

  .login-button {
    white-space: nowrap;
  }
}

@media (max-width: 1120px) {
  body {
    font-size: 14px;
  }

  .signup-button {
    white-space: nowrap;
  }

  .login-button {
    white-space: nowrap;
  }
}
