.partners-demo-banner-outer-container {
  width: 100%;
  margin: 3rem 0;
}
.partners-demo-banner-inner-container {
  max-width: 90%;
  margin: auto;
  height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url("../../public/images/top-view-woman-working-laptop.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}
.partners-demo-banner-banner-title {
  color: #3c3c3c;
  font-size: 35px;
  font-weight: bold;
}
.partners-demo-banner-banner-tagline {
  color: #7b7b7b;
}
