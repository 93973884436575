.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: -webkit-linear-gradient(
    -45deg,
    #183850 0,
    #183850 25%,
    #192c46 50%,
    #22254c 75%,
    #22254c 100%
  );
}

.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 2px solid #203b5a;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.chat-header h1 {
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

.chat-header-nav-buttons {
  display: flex;
  gap: 20px;
}

.chat-messages {
  overflow: auto;
  margin: 0 15%;
  height: 550px;
}

.message {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 30px;
}

.user {
  display: flex;
  justify-content: flex-end;
}
.user::after {
  position: absolute;
  top: 15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
}
.system {
  display: flex;
  justify-content: flex-start;
}

.user-avatar,
.system-icon {
  margin-right: 10px;
}

.chat-messages-container {
  height: 75vh;
}

.user .message-content {
  max-width: 50%;
  padding: 15px 25px;
  border-radius: 5px;
  color: #ffffff;
  margin-right: 10px;
  background-color: rgba(25, 147, 147, 0.2);
}

.system .message-content {
  max-width: 70%;
  padding: 15px 25px;
  border-radius: 5px;
  color: #ffffff;
  background-color: rgba(25, 147, 147, 0.2);
}
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.chat-form {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin: 0 15%;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.chat-form input[type="text"] {
  flex: 1;
  padding: 20px;
  border-radius: 5px;

  border: 1px solid #203b5a;
  outline: none;
  transition: border-bottom 0.3s;
  background-color: transparent !important;
  position: relative;
  color: #fff;
  font-size: 16px;
}

.chat-form input[type="text"]:active,
.chat-form input[type="text"]:focus {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #203b5a !important;
}

.custom-loader {
  width: 30px;
  height: 24px;
  position: absolute;
  bottom: 4.5rem;
  right: 18rem;
  z-index: 1000;
  background: radial-gradient(circle closest-side, #766df4 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #766df4 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #766df4 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
}
@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

.response-container {
  margin: 10px;
}
.chat-form-submit-button {
  margin-top: 5px;
  position: relative;
  right: 50px;
  border: transparent 1px solid;
  background-color: transparent;
  border-radius: 10px;
}

.hide {
  display: none;
}
