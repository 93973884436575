.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  padding: 30px;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  max-height: 60vh;
  overflow-y: auto;
}

.modal-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
}

.modal-paragraph {
  line-height: 1.6;
  margin-bottom: 10px;
}

.agree-button-container {
  display: flex;
  justify-content: flex-end;
}

.agree-button {
  padding: 8px 15px;
  background: #f05742;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.terms-checkbox :hover {
  background-color: #f05742;
}

.terms-checkbox {
  margin-right: 10px;
}

.checkbox-label {
  font-size: 0.9rem;
}
