.phonepe-pulse-chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #210d38;
}

.phonepe-pulse-chat-window {
  width: 70%;
  height: 100%;
  position: relative;
  left: 15%;
  margin: 3rem 0 0 0 !important;
  display: flex;
  flex-direction: column;
  background-color: #ffffff00;
  overflow: hidden;
}

.phonepe-pulse-chat-header-title h1 {
  text-align: center;
  color: #fff;
  margin-top: 25px;
}

.phonepe-pulse-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  margin: 0 20px;
}

.phonepe-pulse-chat-header-nav-buttons {
  display: flex;
  height: auto;
  gap: 20px;
}

.phonepe-pulse-chat-messages {
  overflow: scroll;
  height: 90%;
  width: 80%;
  position: relative;
  left: 50px; 
}



.phonepe-pulse-chat-messages-response {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px;
  background-color: #d9d9d9;
  border-radius: 20px;
  margin: 40px 0;
  padding: 30px;
  /* background-color: rgba(119, 100, 140, 0.37); */
}

.user-request{
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.phonepe-pulse-chat-form-container {
  background-color: transparent;
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem ;
}
.phonepe-input-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

.phonepe-input-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.phonepe-input-field::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}

.phonepe-pulse-chat-form input[type="text"] {
  flex: 1;
  padding: 20px;
  border-radius: 5px;
  width: 70%;
  outline: none;
  border: none;
  transition: border-bottom 0.3s;
  background-color: transparent !important;
  position: relative;
  left: 50px;
  color: #ffffff;
  font-size: 16px;
  border-bottom: 3px solid #ffffff !important;
}

.phonepe-pulse-chat-form input[type="text"]:active,
.phonepe-pulse-chat-form input[type="text"]:focus {
  background-color: transparent !important;
}

.phonepe-custom-loader {
  width: 30px;
  height: 24px;
  margin-top: 10px;
  z-index: 1000;
  background: radial-gradient(circle closest-side, #d9d9d9 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #d9d9d9 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #d9d9d9 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
}
@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

.response-container {
  margin: 10px;
}
.phonepe-pulse-chat-form-submit-button {
  position: absolute;
  right: 13%;
  top: 5px;
  border: transparent 1px solid;
  background-color: transparent;
  border-radius: 10px;
}

.hide {
  display: none;
}
