.supreme-court-chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* background: -webkit-linear-gradient(
      -45deg,
      #183850 0,
      #183850 25%,
      #192c46 50%,
      #22254c 75%,
      #22254c 100%
    ); */
    background-color: #1515159b;
    padding: 20px;
  }
  
  .supreme-court-chat-window-container {
    flex: 1;
    display: flex;
    gap: 40px;
    /* border: 2px solid #203b5a; */
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
  }
  .supreme-court-chat-window-sidebar{
    width: 60%;
    background-color: #5a5a5a;
    /* background-image: url("/public/images/demo-images/still-life-with-scales-justice\ \(1\).jpg"); */
    border-radius: 30px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-repeat: no-repeat;
  }
  .supreme-court-chat-window-sidebar-title h2{
    color: #fff;

  }
  .supreme-court-chat-window-sidebar-image img{
    border-radius: 30px;
  }
  .supreme-court-chat-window{
    width: 100%;
  }
  
  .supreme-court-chat-header h1 {
    text-align: center;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .supreme-court-chat-header {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }
  
  .supreme-court-chat-header-nav-buttons {
    display: flex;
    gap: 20px;
  }
  
  .supreme-court-chat-messages {
    overflow: auto;
    height: 550px;
  }
  
  .message {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 30px;
  }
  
  .supreme-court-chat-messages-question {
    display: flex;
    justify-content: flex-end;
  }
  .supreme-court-chat-messages-question::after {
    position: absolute;
    top: 15px;
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid rgba(25, 147, 147, 0.2);
  }
  .supreme-court-chat-messages-answer {
    display: flex;
    justify-content: flex-start;
  }
  
  .user-avatar,
  .system-icon {
    margin-right: 10px;
  }
  
  .supreme-court-chat-messages-container {
    height: 75vh;
  }
  
  .supreme-court-chat-messages-question .message-content {
    max-width: 50%;
    padding: 15px 25px;
    border-radius: 5px;
    color: #ffffff;
    margin-right: 10px;
    background-color: #686767;
  }
  
  .supreme-court-chat-messages-answer .message-content {
    max-width: 80%;
    padding: 15px 25px;
    border-radius: 5px;
    color: #ffffff;
    line-height: 1.7;
    background-color: #5a5a5a;
  }
  ::-webkit-scrollbar {
    width: 1px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  .supreme-court-chat-form {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }
  
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }
  
  .supreme-court-chat-form input[type="text"] {
    flex: 1;
    padding: 20px;
    border-radius: 5px;
  
    border: 1px solid #5a5a5a;
    outline: none;
    transition: border-bottom 0.3s;
    background-color: transparent !important;
    position: relative;
    color: #fff;
    font-size: 16px;
  }
  
  .supreme-court-chat-form input[type="text"]:active,
  .supreme-court-chat-form input[type="text"]:focus {
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid #5a5a5a !important;
  }
  
  .supreme-court-custom-loader {
    width: 30px;
    height: 24px;
    position: absolute;
    bottom: 3.2rem;
    right: 6rem;
    z-index: 1000;
    background: radial-gradient(circle closest-side, #2d2d2d 90%, #0000) 0% 50%,
      radial-gradient(circle closest-side, #2d2d2d 90%, #0000) 50% 50%,
      radial-gradient(circle closest-side, #2d2d2d 90%, #0000) 100% 50%;
    background-size: calc(100% / 3) 12px;
    background-repeat: no-repeat;
    animation: d3 1s infinite linear;
  }
  @keyframes d3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }
  
  @keyframes s3 {
    100% {
      transform: rotate(1turn);
    }
  }
  
  .response-container {
    margin: 10px;
  }
  .supreme-court-chat-form-submit-button {
    margin-top: 5px;
    position: relative;
    right: 50px;
    border: transparent 1px solid;
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .hide {
    display: none;
  }
  