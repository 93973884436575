/* .ant-drawer-content-wrapper{
    width: 700px !important;
} */
span.anticon.anticon-close {
  position: absolute;
  right: 20px;
  top: 20px;
}
h2,
h1 {
  color: #f05742;
}
.ant-drawer-body p {
  padding: 10px 0;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 10px;
  color: 606060;
}

th:nth-child(1),
td:nth-child(1) {
  padding-right: 20px;
}

th:nth-child(2),
td:nth-child(2) {
  padding: 10px 20px;
}

th:nth-child(3),
td:nth-child(3) {
  padding-left: 20px;
}

ol li {
  margin-left: 30px;
  padding: 10px;
}
