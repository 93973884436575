.partners-demo-header-outer-container {
  width: 100%;
  height: 70px;
  padding: 10px 0;
  margin: auto;
  border-bottom: 1px solid #e0e0e0;
}

.partners-demo-header-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.partners-demo-header-logo img {
  width: 150px;
}

.partners-demo-header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.partners-demo-web-header-userprofile {
  display: flex;
  gap: 10px;
  align-items: center;
}
.partners-demo-header-right-buttons {
  display: flex;
  align-items: center;
  gap: 50px;
}

.partners-demo-header-right-docs span {
  cursor: pointer;
}
.partners-demo-header-right-buttons a {
  color: #3c3c3c;
  text-decoration: none;
}

.partners-demo-header-logout {
  background-color: #f05742;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 35px;
  cursor: pointer;
}

.partners-demo-web-header-userprofile img {
  width: 2.5rem;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .partners-demo-header-outer-container {
    height: auto;
  }

  .partners-demo-header-inner-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .partners-demo-header-right {
    gap: 10px;
  }
  .partners-demo-header-right-buttons {
    gap: 15px;
  }

  .partners-demo-header-logout {
    background-color: #f05742;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}
