.quill {
  background-color: #fff !important;
  border-radius: 10px;
  height: 500px;
  overflow: scroll;
}
.ql-container.ql-snow {
  border: none !important;
}
.ql-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 15px !important;
}
.ql-toolbar.ql-snow {
  border: none !important;
}
